var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('c-box',{attrs:{"paddingTop":"30px","paddingBottom":"80px"}},[_c('c-box',{attrs:{"w":"100%","maxWidth":"1200px","mx":"auto"}},[_c('c-breadcrumb',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          ol: {
            li: {
              a: {
                fontSize: '14px',
                fontFamily: 'Roboto',
              },
              span: {
                fontSize: '14px',
                fontFamily: 'Roboto',
              },
            },
          },
        }),expression:"{\n          ol: {\n            li: {\n              a: {\n                fontSize: '14px',\n                fontFamily: 'Roboto',\n              },\n              span: {\n                fontSize: '14px',\n                fontFamily: 'Roboto',\n              },\n            },\n          },\n        }"}],attrs:{"marginTop":"20px","marginBottom":"20px"}},[_c('c-breadcrumb-item',[_c('c-breadcrumb-link',{attrs:{"href":"#","color":"brand.900","fontSize":"14px","fontFammily":"Roboto"}},[_vm._v(" Beranda ")])],1),_c('c-breadcrumb-item',{attrs:{"isCurrentPage":""}},[_c('c-breadcrumb-link',{attrs:{"fontSize":"14px !important","fontFammily":"Roboto !important"}},[_vm._v(" Term & Condition ")])],1)],1),_c('c-heading',{attrs:{"as":"h2","fontSize":"36px","fontWeight":"600","color":"black.900","marginBottom":"40px"},domProps:{"innerHTML":_vm._s(_vm.item.Title)}}),_c('c-box',{attrs:{"borderTop":"1px solid #F2F2F2","paddingTop":"30px"}},[_c('c-text',{attrs:{"fontSize":"16px","lineHeight":"28px","color":"darkGrey.900","marginBottom":"30px"},domProps:{"innerHTML":_vm._s(_vm.item.content)}})],1),_vm._l((_vm.item.termsAndCondition),function(child,idx){return _c('c-box',{key:idx},[_c('c-heading',{attrs:{"as":"h3","fontSize":"18px","lineHeight":"27px","fontWeight":"700","color":"brand.900","marginBottom":"10px"},domProps:{"innerHTML":_vm._s(child.subTitle)}}),_c('c-text',{attrs:{"fontSize":"16px","lineHeight":"28px","color":"darkGrey.900","marginBottom":"30px"},domProps:{"innerHTML":_vm._s(child.subContent)}})],1)})],2)],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }