<template>
  <div>
    <Header />
    <c-box paddingTop="30px" paddingBottom="80px">
      <c-box w="100%" maxWidth="1200px" mx="auto">
        <c-breadcrumb
          marginTop="20px"
          marginBottom="20px"
          v-chakra="{
            ol: {
              li: {
                a: {
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                },
                span: {
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                },
              },
            },
          }"
        >
          <c-breadcrumb-item>
            <c-breadcrumb-link
              href="#"
              color="brand.900"
              fontSize="14px"
              fontFammily="Roboto"
            >
              Beranda
            </c-breadcrumb-link>
          </c-breadcrumb-item>

          <c-breadcrumb-item isCurrentPage>
            <c-breadcrumb-link
              fontSize="14px !important"
              fontFammily="Roboto !important"
            >
              Term & Condition
            </c-breadcrumb-link>
          </c-breadcrumb-item>
        </c-breadcrumb>
        <c-heading
          as="h2"
          fontSize="36px"
          fontWeight="600"
          color="black.900"
          marginBottom="40px"
          v-html="item.Title"
        >
        </c-heading>
        <c-box borderTop="1px solid #F2F2F2" paddingTop="30px">
          <c-text
            fontSize="16px"
            lineHeight="28px"
            color="darkGrey.900"
            marginBottom="30px"
            v-html="item.content"
          >
          </c-text>
        </c-box>
        <c-box v-for="(child, idx) in item.termsAndCondition" :key="idx">
          <c-heading
            as="h3"
            fontSize="18px"
            lineHeight="27px"
            fontWeight="700"
            color="brand.900"
            marginBottom="10px"
            v-html="child.subTitle"
          />
          <c-text
            fontSize="16px"
            lineHeight="28px"
            color="darkGrey.900"
            marginBottom="30px"
            v-html="child.subContent"
          />
        </c-box>
      </c-box>
    </c-box>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/index.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters } from "vuex";

export default {
  name: "TermConditionPage",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      item: "",
    };
  },
  computed: {
    ...mapGetters({
      axiosCMS: "customAxiosCMS",
    }),
  },
  async mounted() {
    this.loadPrivacyPolicy();
  },
  methods: {
    loadPrivacyPolicy() {
      this.axiosCMS.get("/terms-and-condition").then((res) => {
        this.item = res.data;
      });
    },
  },
};
</script>

<style>
</style>
